const meta = {
  module: "profile",
};

export default [
  {
    path: `/profile/:id?/tab/:action`,
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/modules/profile/views/index.vue"
      ),
    meta: {
      ...meta,
    },
  },
  {
    path: `/auth/register/:id?`,
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/modules/auth/views/register.vue"
      ),
    meta: {
      ...meta,
    },
  },
  {
    path: `/profile/:id?`,
    redirect: "profile/:id?/tab/personal",
  },
  {
    path: `/profile/:id?/tab`,
    redirect: "profile/:id?/tab/personal",
  },
  {
    path: `/investigation/share/:id?`,
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/modules/share/views/index.vue"
      ),
    meta: {
      ...meta,
    },
  },
];
