import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        /* INNIRM color */
        "bright-innirm": "#f7fcfc",
        "brighter-innirm": "#f0fafa",
        "slightly-brighter-innirm": "#d9f1f2",
        innirm: "#3fbbc0",
        "innirm-footer-background": "#f6f6f6",
        primary: "#3fbbc0",
        secondary: "#262B33",
        "light-secondary": "#666666",
        light: "#EBEFF2",
        selected: "#F5F6F8",
        grey: "#FCFCFC",
        "light-gray": "#81848B",
        "lighter-gray": "#ECECEC",
        "expanded-row-header": "#23B7C9",
        "expanded-row": "#f8f8f8",
        /* Text colors */
        "bright-text": "#848484",
        "brighter-text": "#7b7b7b",
        text: "#444444",
        "darker-text": "#555555",
        blue: "#46BCFF",
        darkBlue: "#00233C",
        purple: "#965BF7",
        error: "#F7685B",
        success: "#2ED47A",
        warning: "#FFB948",
        hover: "#393c41",
        "odd-row": "#f9f9f9",
        /* NavBar Colors */
        "nav-bar-color": "#ffffff",
        "nav-bar-icons": "#ffffff",
        /* SideBar Colors */
        "side-bar-text-color": "#81848B",
        "side-bar-header-color": "#666666",
        "side-bar-color": "#262B33",
        "side-bar-hovered-color": "#393c41",
        "side-bar-selected-text-color": "#F5F6F8",
        "side-bar-accent-color": "#1b1f24",
        "table-header-color": "#fcfcfc",
        "card-color": "#ffffff",
        "scroll-color": "#e0dfe1",
        /* Pricing description */
        "pricing-description-header-background": "#f8f8f8",
        "pricing-description-header-text": "#777777",
      },
      dark: {
        /* INNIRM color */
        "bright-innirm": "#f7fcfc",
        "brighter-innirm": "#f7fcfc",
        "slightly-brighter-innirm": "#d9f1f2",
        innirm: "#3fbbc0",
        "innirm-footer-background": "#f6f6f6",
        primary: "#3fbbc0",
        secondary: "#262B33",
        "light-secondary": "#666666",
        light: "#EBEFF2",
        selected: "#F5F6F8",
        grey: "#1e1e1e",
        "light-gray": "#81848B",
        "lighter-gray": "#ECECEC",
        /* Text colors */
        "bright-text": "#848484",
        "brighter-text": "#7b7b7b",
        text: "#eaeaea",
        "darker-text": "#555555",
        blue: "#46BCFF",
        darkBlue: "#00233C",
        purple: "#965BF7",
        error: "#F7685B",
        success: "#2ED47A",
        warning: "#FFB948",
        hover: "#393c41",
        "odd-row": "#f9f9f9",
        /* NavBar Colors */
        "nav-bar-color": "#1e1e1e",
        "nav-bar-icons": "#ffffff",
        /* SideBar Colors */
        "side-bar-text-color": "#81848B",
        "side-bar-header-color": "#666666",
        "side-bar-color": "#262B33",
        "side-bar-hovered-color": "#393c41",
        "side-bar-selected-text-color": "#F5F6F8",
        "side-bar-accent-color": "#1b1f24",
        "table-header-color": "#1e1e1e",
        "card-color": "#1e1e1e",
        "scroll-color": "#484848",
      },
    },
  },
});
