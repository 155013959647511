import { AxiosResponse } from "axios";
import APIModel from "../APIModel";

export default class Profile extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "profile";
  }

  async getPersonalData(): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}/personal_data`, {});
  }

  async getHistory(): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}/investigations_history`, {});
  }

  async getPatients(): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}/patients`, {});
  }

  async searchDoctorEmail(
    query: any,
    text = "email",
    label = "name"
  ): Promise<any> {
    const params = {
      fields: {
        value: "id",
        text: text,
        label: label,
      },
      filter: {
        [text]: query,
      },
      route: "v1.doctors.index.get",
    };
    return await this.http.get(`doctors/live_search`, params);
  }

  async getPatientInfo(id: number): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}/investigations_history/${id}`, { id });
  }

  async getSettings(): Promise<AxiosResponse> {
    return await this.http
      .get(`${this.resourceUrl}/patient_settings`, {});
  }

  async getDoctors(): Promise<AxiosResponse> {
    return await this.http
      .get(`${this.resourceUrl}/allowed_doctors`, {});
  }

  async getInvestigation(uuid: string): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .get(`investigation/share/${uuid}`, {});
  }

  async share(payload: any): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .ignoreErrorHandler()
      .post(`${this.resourceUrl}/share_investigations`, payload);
  }

  async allowPermission(doctors: any): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .ignoreErrorHandler()
      .post(`${this.resourceUrl}/allow_permissions`, { doctors });
  }

  async changeSecurity(payload: any): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .ignoreErrorHandler()
      .post(`${this.resourceUrl}/update_settings`, payload);
  }

  async changeData(id: number, payload: any): Promise<AxiosResponse> {
    return await this.http
      .ignoreErrorHandler()
      .useFormData()
      .post(`users/${id}?_method=PATCH`, payload);
  }
}
