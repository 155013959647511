export default [
  {
    path: `/privacy`,
    name: "privacy",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../../modules/privacy/views/privacy"
      ),
    meta: {
      module: "privacy",
    },
  },
  {
    path: `/terms`,
    name: "terms",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../../modules/privacy/views/terms"
      ),
    meta: {
      module: "terms",
    },
  },
];
