import Vue from "vue";
import App from "./App.vue";
//@ts-ignore
import router from "./router";
//@ts-ignore
import store from "./store";
import moment from "moment";
//@ts-ignore
import vuetify from "./plugins/vuetify";
import Api from "@/api/API";
//@ts-ignore
import * as VueGoogleMaps from "vue2-google-maps";
//@ts-ignore
import i18n from "./plugins/i18n";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBzlLYISGjL_ovJwAehh6ydhB56fCCpPQw",
    libraries: "places",
  },
});

Vue.prototype.$API = Api;
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  //@ts-ignore
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
