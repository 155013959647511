import { AxiosResponse } from "axios";
import APIModel from "../APIModel";

export default class Auth extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "oauth";
  }

  async login(payload: object): Promise<AxiosResponse> {
    return await this.http.ignoreErrorHandler().post(`/login`, payload);
  }

  async checkId(uuid: string): Promise<AxiosResponse> {
    return await this.http
      .ignoreErrorHandler()
      .get(`/users/check_registration_token/${uuid}`, { uuid });
  }

  async confirmRegistration(payload: any): Promise<AxiosResponse> {
    return await this.http
      .ignoreErrorHandler()
      .post(`users/confirm_registration`, payload);
  }

  async forgot(payload: object): Promise<AxiosResponse> {
    return await this.http
      .ignoreGlobalPreloader()
      .post(`${this.resourceUrl}/login`, payload);
  }
}
