import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
// import middleware from "./middleware";
import goTo from "vuetify/es5/services/goto";

import home from "./modules/home";
import confirm from "./modules/confirm";
import about from "./modules/about";
import privacy from "./modules/privacy";
import profile from "@/router/modules/profile";
import authentication from "@/router/modules/authentication";

Vue.use(VueRouter);

const routes = [
  ...home,
  ...confirm,
  ...about,
  ...privacy,
  ...profile,
  ...authentication,
];

store.dispatch("localization/setFromRouter");

const router = new VueRouter({
  mode: "history",
  base: store.getters["localization/getCurrent"],
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      console.log(savedPosition.y);
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo, {
      duration: 300,
    });
  },
  routes,
});

router.beforeEach((to, from, next) => {
  next(() => {
    window.scrollTo(0, 0);
  });
});

// middleware.apply(router);

export default router;
