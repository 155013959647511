<script>
export default {
  name: "AppLayout",

  components: {
    default: () => import(/* webpackChunkName: "default" */ `./default.vue`),
  },

  computed: {
    layout() {
      return this.$options.components?.[this.$route.meta.layout || "default"];
    },
  },
};
</script>
<template>
  <component :is="layout"></component>
</template>

<style scoped></style>
