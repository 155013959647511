<script>
import AppLayout from "@/layouts/index.vue";

export default {
  name: "App",

  components: {
    AppLayout,
  },

  data: () => ({}),
};
</script>

<template>
  <v-app>
    <app-layout></app-layout>
  </v-app>
</template>

<style>
*::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  background: var(--v-card-color-base);
}

*::-webkit-scrollbar-thumb {
  background: var(--v-scroll-color-base);
  border-radius: 16px;
}

*::-webkit-scrollbar-thumb:hover {
  background: var(--v-scroll-color-base);
  border-radius: 16px;
}
</style>
