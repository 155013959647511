const meta = {
  module: "auth",
  layout: "default",
  withoutCredentials: true,
};

export default [
  {
    path: `/auth/login`,
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/modules/auth/views/login.vue"),
    meta: {
      ...meta,
    },
  },
];
