const meta = {
  module: "confirm",
};

export default [
  {
    path: `/online-requests/confirm/:uuid`,
    name: "confirm",
    component: () =>
      import(
        /* webpackChunkName: "confirm" */ "../../modules/response/views/confirm"
      ),
    meta: {
      ...meta,
    },
  },
  {
    path: `/online-requests/confirm`,
    redirect: `/online-requests/confirm-error`,
  },
  {
    path: `/online-requests/confirm-error`,
    name: "confirm",
    component: () =>
      import(
        /* webpackChunkName: "confirm" */ "../../modules/response/views/confirm-error"
      ),
    meta: {
      ...meta,
    },
  },
];
